<template>
  <div>
    <div class="top">
        <div class="title">工作日历</div>
        <div class="legend">
            <div class="circle1"></div>有日报
            <div class="circle2"></div>选中
            <div class="circle3"></div>定位今天
        </div>
    </div>
    <div class="calendar">
        <el-select v-model="year" placeholder="请选择" size="small" class="yearSelect" @change="setYear">
            <el-option
                v-for="item in yearList"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
        </el-select>
        <el-calendar v-model="calendarDate">
            <template slot="dateCell" slot-scope="{data}">
            <div :class="{'choseDay': data.isSelected, 'isReportDay': isReportList.indexOf(data.day) > -1, 'isNowDate': data.day == nowDate }">
                <p>{{ data.day.slice(8) }}</p>
            </div>
            </template>
        </el-calendar>
    </div>
    <div class="down">
        <div class="down-top">
            <div class="title">日报</div>
            <div class="right" v-if="log" @click="share()">分享日报</div>
        </div>
        <div style="background-color: #FFFFFF;">
            <el-input
                v-if="log"
                v-model="log"
                :readonly="true"
                class="log-content"
                resize="none"
                autosize
                type="textarea"
                placeholder="工作日志"
                >
            </el-input>
            <div class="empty" v-else>
                <img :src="require('@/assets/img/noreport.png')" alt="">
                <span>暂无工作日志</span>
            </div>
        </div>
        <div class="button">
            <el-button class="editor-btn" @click="editorReport">编辑</el-button>
        </div>
    </div>
  </div>
</template>

<script>
import {formatterDate, getfirstDateAndlastDate} from '@/utils/utils'
export default {
    data() {
        return {
            calendarDate: new Date(),
            year: (new Date()).getFullYear(),
            yearList: [],
            isReportList: [],
            nowDate: formatterDate(new Date()),
            log: '',
        }
    },
    created() {
        this.getYearList()
        this.getLog()
    },
    watch: {
        calendarDate: {
            handler (val) {
                this.getLog()
            }
        }
    },
    methods: {
        getLog () {
            this.$http({
                url: this.$http.adornUrl('/wxapp/work/log/getLog'),
                method: 'get',
                params: this.$http.adornParams({
                    day: formatterDate(this.calendarDate)
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                this.log = data.log
                }
            })
            this.beginDate = getfirstDateAndlastDate(this.calendarDate)[0]
            this.endDate = getfirstDateAndlastDate(this.calendarDate)[1]
            this.$http({
                url: this.$http.adornUrl('/wxapp/work/log/getLogByDay'),
                method: 'get',
                params: this.$http.adornParams({
                beginDay: this.beginDate,
                endDay: this.endDate
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.isReportList = data.logList.map(item => item.day)
                }
            })
        },
        getYearList () {
            let nowYear = (new Date()).getFullYear()
            let arrYear = nowYear - 2019
            for (let i = 0; i <= arrYear; i++) {
                this.yearList.push(nowYear--)
            }
        },
        setYear (val) {
            this.calendarDate = new Date(Date.parse(this.calendarDate) + (val - this.calendarDate.getFullYear()) * 31536000000)
        },
        editorReport() {
            let date = formatterDate(this.calendarDate)
            this.$router.push({path: '/daily-paper-editor',query: {date}})
        },
        share() {
            wx.miniProgram.navigateTo({
              url: `/pages/my/ShareDaily?text=${this.log}`
            })
        },
    }
}
</script>

<style scoped lang="scss">
.top {
    height: 96px;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .title {
        font-size: 32px;
        font-family: 'PingFang Bold';
        font-weight: 600;
        color: #333333;
    }
    .legend {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        color: #666666;
        .circle1 {
            margin: 0 12px 0 50px;
            width: 24px;
            height: 24px;
            background: #D1E0FF;
            border-radius: 50%;
        }
        .circle2 {
            margin: 0 12px 0 50px;
            width: 24px;
            height: 24px;
            background: #4581F8;
            border-radius: 50%;
            box-shadow: 0px 8px 9px 0px rgba(69,129,248,0.44);
        }
        .circle3 {
            margin: 0 12px 0 50px;
            width: 24px;
            height: 24px;
            background: #FFA447;
            border-radius: 50%;
        }
    }
}
.empty {
    margin-top: 20px;
    height: 419px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 320px;
      height: 273px;
    }
    span {
      font-size: 14PX;
      margin-top: 20px;
      color: #999;
      line-height: 20px;
      color: rgba(153,153,153,0.85);
    }
}
.calendar {
    position: relative;
    // margin-top: 20PX;
    // height: 430PX;
    // border-radius: 4PX;
    // border: 1PX solid #E3E5ED;
    overflow-y: auto;
    ::v-deep .el-calendar__button-group {
      width: 100%;
    }
    ::v-deep .el-calendar__body {
      padding: 0;
    }
    ::v-deep .el-calendar-table {
      border-spacing: 15PX;
      thead th {
        padding: 0;
        font-size: 14PX;
        color: #BBBBBB;
        height: 24PX;
        width: 38PX;
        margin-right: 20PX;
        border-radius: 4PX;
        border: 1PX solid #E3E5ED;
      }
    }
    ::v-deep .el-calendar-table tr td:first-child{
      border: none;
    }
    ::v-deep .el-calendar-table tr td:first-child{
      border: none;
    }
    ::v-deep .el-calendar-table tr:first-child td{
      border: none;
    }
    ::v-deep .el-calendar-table:not(.is-range) {
      color: rgba(156, 178, 205, 1);
    }
    ::v-deep .el-calendar-table:not(.is-range) td.next {
      display: none;
    }
    ::v-deep .el-calendar-table:not(.is-range) td.prev {
      visibility: hidden;
    }
    ::v-deep .el-calendar-table__row td{
      border: none;
    }
    ::v-deep .el-calendar-table td.is-selected {
      background-color: transparent;
    }
    ::v-deep .el-calendar__header {
      padding: 76PX 0 0 0;
      border-bottom: none;
      .el-calendar__title {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        font-size: 16PX;
        color: #333;
      }
    }
    ::v-deep .el-button-group {
      display: flex;
      justify-content: space-between;
    }

    ::v-deep .el-button-group::after, .el-button-group::before {
      content: unset;
    }

    ::v-deep .el-button-group > .el-button:not(:first-child):not(:last-child) {
      position: relative;
      left: 96PX;
      bottom: 56PX;
      box-shadow: 0PX 2PX 12PX 0PX rgba(51,51,51,0.12);
      border-radius: 4PX;
      border: none;
    }
    ::v-deep .el-button-group > .el-button:not(:first-child):not(:last-child) span {
      display: none;
    }
    ::v-deep .el-button-group > .el-button:not(:first-child):not(:last-child):before {
      content: '回今天';
      font-size: 24px;
      color: #4581F8;
    }
    ::v-deep .el-button-group > .el-button:first-child:before {
      content: '<';
      font-size: 16PX;
      color: #999;
    }

    ::v-deep .el-button-group > .el-button:last-child:before {
      content: '>';
      font-size: 16PX;
      color: #999;
    }

    ::v-deep .el-button-group > .el-button:first-child {
      position: relative;
      left: -10PX;
      box-shadow: 0PX 2PX 12PX 0PX rgba(51,51,51,0.12);
      border-radius: 4PX;
      border: none;
    }

    ::v-deep .el-button-group > .el-button:last-child {
      position: relative;
      left: -50PX;
      box-shadow: 0PX 2PX 12PX 0PX rgba(51,51,51,0.12);
      border-radius: 4PX;
      border: none;
    }

    ::v-deep .el-button-group > .el-button:first-child span {
      display: none;
    }
    ::v-deep.el-button-group > .el-button:last-child span {
      display: none;
    }

    ::v-deep.el-calendar .el-calendar-table .el-calendar-day{
      padding: 0PX;
      text-align: center;
      font-size: 14PX;
      color: #999;
      background-color: rgb(255, 255, 255);
      height: 16PX;
    }
}
.isNowDate {
  color: #333;
  position: relative;
  &:after {
    content: '';
    width: 5PX;
    height: 5PX;
    border-radius: 50%;
    background: #FFA447;
    position: absolute;
    bottom: -5PX;
    left: 50%;
    transform: translateX(-50%);
  }
}
.isReportDay {
  position: relative;
  top: -12PX;
  width: 36PX;
  height: 36PX;
  background: #D1E0FF;
  color: #4581F8;
  border-radius: 30PX;
  line-height: 35PX;
  z-index: 6;
  &:after {
    bottom: 5PX;
  }
}
.choseDay {
  position: relative;
  top: -12PX;
  width: 36PX;
  height: 36PX;
  background: #4581F8;
  box-shadow: 0PX 8PX 9PX 0PX rgba(69,129,248,0.44);
  color:#fff;
  border-radius: 30PX;
  line-height: 35PX;
  z-index: 6;
  &:after {
    bottom: 5PX;
  }
}
.yearSelect {
  position: absolute;
  top: 20PX;
  left: 20PX;
  width: 120PX;
}
.down {
    margin-top: 24px;
    padding: 0 30px;
    background: #FFF;
    .down-top {
        height: 96px;
        line-height: 96px;
        font-size: 32px;
        font-family: 'PingFang Bold';
        font-weight: 600;
        color: #333333;
        border-bottom: 2px solid #EEEEEE;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right {
          width: 168px;
          height: 58px;
          border-radius: 8px;
          border: 2px solid #1677FF;
          font-size: 30px;
          font-family: 'PingFangSC-Regular';
          font-weight: 400;
          color: #1677FF;
          line-height: 58px;
          text-align: center;
        }
    }
    .button {
        .editor-btn {
            width: 100%;
            height: 88px;
            background: #FFFFFF;
            border-radius: 8px;
            border: 2px solid #4581F8;
            font-size: 32px;
            font-family: 'PingFang Bold';
            font-weight: 600;
            color: #4581F8;
            margin-bottom: 40px;
        }
    }
}
</style>
<style scoped>
.down >>> .el-textarea__inner {
    padding: 20px 0;
    border: none;
}
</style>